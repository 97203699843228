import { useAnimations, useGLTF } from "@react-three/drei";
import React, { useEffect, useRef, useState } from "react";
import { useRPMLipsync } from "../../hooks/useRPMLipsync";
import { useHeadTracking } from "../../hooks/useHeadTracking";

export function Anita(props) {
    const { nodes, materials, scene } = useGLTF("/models/anita_last.glb");
    const { animations } = useGLTF("/animations/animations_last.glb");
    const anitaRef = useRef();
    const { actions, mixer } = useAnimations(animations, anitaRef);
    const [animation, setAnimation] = useState(
        //animations.find((a) => a.name === "Idle") ? "Idle" : animations[0].name // Check if Idle animation exists otherwise use first animation
        animations.find((a) => a.name === "idle neutral") ? "idle neutral" : animations[0].name
    );
    const { client } = props;
    useEffect(() => {
        if (client?.isTalking) {
            //setAnimation("Talking_0");
            setAnimation("idle neutral");
        } else {
            //setAnimation("Idle");
            setAnimation("idle neutral");
        }
    }, [client?.isTalking]);

    useEffect(() => {
        console.log("animation:", animation);
        console.log("actions:", actions);
        //if (actions[animation]) {
        const clipAction = actions[animation];
        clipAction.reset();
        //clipAction.loop = false;
        clipAction
            .fadeIn(mixer.stats.actions.inUse === 0 ? 0 : 0.7)
            .setEffectiveTimeScale(0.5)
            .play();
        //}
        return () => clipAction.fadeOut(0.5);
    }, [animation]);
   

    useRPMLipsync({ client, nodes, scene });
    useHeadTracking({ client, nodes, RPM: true });
    return (
        <group
            {...props}
            dispose={null}
            ref={anitaRef}
            position={[-1.4, 0, -0.9]}
            rotation={[0, 1.3, 0]}
        >
            <primitive object={nodes.Hips} />
            <skinnedMesh
                name="Wolf3D_Body"
                geometry={nodes.Wolf3D_Body.geometry}
                material={materials.Wolf3D_Body}
                skeleton={nodes.Wolf3D_Body.skeleton}
            />
            <skinnedMesh
                name="Wolf3D_Outfit_Bottom"
                geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
                material={materials.Wolf3D_Outfit_Bottom}
                skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
            />
            <skinnedMesh
                name="Wolf3D_Outfit_Footwear"
                geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
                material={materials.Wolf3D_Outfit_Footwear}
                skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
            />
            <skinnedMesh
                name="Wolf3D_Outfit_Top"
                geometry={nodes.Wolf3D_Outfit_Top.geometry}
                material={materials.Wolf3D_Outfit_Top}
                skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
            />
            <skinnedMesh
                name="Wolf3D_Hair"
                geometry={nodes.Wolf3D_Hair.geometry}
                material={materials.Wolf3D_Hair}
                skeleton={nodes.Wolf3D_Hair.skeleton}
            />
            <skinnedMesh
                name="EyeLeft"
                geometry={nodes.EyeLeft.geometry}
                material={materials.Wolf3D_Eye}
                skeleton={nodes.EyeLeft.skeleton}
                morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
                morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
            />
            <skinnedMesh
                name="EyeRight"
                geometry={nodes.EyeRight.geometry}
                material={materials.Wolf3D_Eye}
                skeleton={nodes.EyeRight.skeleton}
                morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
                morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
            />
            <skinnedMesh
                name="Wolf3D_Head"
                geometry={nodes.Wolf3D_Head.geometry}
                material={materials.Wolf3D_Skin}
                skeleton={nodes.Wolf3D_Head.skeleton}
                morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
                morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
            />
            <skinnedMesh
                name="Wolf3D_Teeth"
                geometry={nodes.Wolf3D_Teeth.geometry}
                material={materials.Wolf3D_Teeth}
                skeleton={nodes.Wolf3D_Teeth.skeleton}
                morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
                morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
            />
        </group>
    );
}

useGLTF.preload("/models/anita_last.glb");
useGLTF.preload("/animations/animations_last.glb");
